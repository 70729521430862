import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SnackBarService } from '../../services/snackbar.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private snackBarService: SnackBarService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            
            if (err.status === 403) {
                this.snackBarService.openSnackBar('Er is een fout opgetreden: ' + err.message, 'Sluiten');
            }

            if (err.status === 500) {
                this.snackBarService.openSnackBar('Er is een fout opgetreden: ' + err.message, 'Sluiten');
            }

            const error = err.error.message || err;
            return throwError(error);
        }));
    };
}
