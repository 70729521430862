import { Injectable } from '@angular/core';
import { OfflineData } from '../models/localstoarge.model';
import { forkJoin, Observable } from 'rxjs';
import { ApiService } from './api.service';
import 'rxjs/add/operator/map';

@Injectable()
export class LocalStorageService {
    constructor(private apiservice: ApiService) { }

    getSearchData(): Promise<OfflineData> {
        return new Promise<OfflineData>((resolve, reject) => {
            if (localStorage.getItem('OfflineData') == undefined) {
                this.saveData().subscribe(res => {
                    resolve(JSON.parse(localStorage.getItem('OfflineData')));
                }, error => {
                    reject(error);
                });
            } else {
                resolve(JSON.parse(localStorage.getItem('OfflineData')));
            }
        });
    }

    saveData(): Observable<any> {
        return forkJoin([
            this.apiservice.getBodyTypes().map(res => res),
            this.apiservice.getTransmissionTypes().map(res => res),
            this.apiservice.getTransmissionTypes(25, 1, JSON.stringify([{ "field": "sequence", "direction": "ASC" }]), null, null, null, '["transmissionType"]').map(res => res),
            this.apiservice.getVehicleTypes().map(res => res),
            this.apiservice.getColorTypes().map(res => res),
            this.apiservice.getUsedAsTypes().map(res => res),
            this.apiservice.getAircoStatusTypes().map(res => res),
            this.apiservice.getConditionTypes(100, 1, JSON.stringify([{ "field": "sequence", "direction": "ASC" }])).map(res => res),
            this.apiservice.getCountryOfOriginTypes(100, 1, JSON.stringify([{ "field": "descriptionNl", "direction": "ASC" }])).map(res => res),
            this.apiservice.getDriveTypes(100, 1, JSON.stringify([{ "field": "descriptionNl", "direction": "ASC" }])).map(res => res),
            this.apiservice.getInteriorTouchTypes(100, 1, JSON.stringify([{ "field": "descriptionNl", "direction": "ASC" }])).map(res => res),
            this.apiservice.getLacTypes(100, 1, JSON.stringify([{ "field": "descriptionNl", "direction": "ASC" }])).map(res => res),
            this.apiservice.getLiningTypes(100, 1, JSON.stringify([{ "field": "descriptionNl", "direction": "ASC" }])).map(res => res),
            this.apiservice.getInteriorColorTypes(100, 1, JSON.stringify([{ "field": "descriptionNl", "direction": "ASC" }])).map(res => res),
            this.apiservice.getMaintenanceGuideTypes().map(res => res),
            this.apiservice.getMileageHistoryStatusTypes().map(res => res),
            this.apiservice.getTiresTypes(100, 1, JSON.stringify([{ "field": "sequence", "direction": "ASC" }])).map(res => res),
            this.apiservice.getExtraTiresTypes(100, 1, JSON.stringify([{ "field": "sequence", "direction": "ASC" }])).map(res => res),
            this.apiservice.getEmployees(50, 1, null, null, JSON.stringify(["Person", "Function"]), null),
        ])
            .map((data: any[]) => {
                const resData = {
                    synced: true,
                    syncDate: String(new Date()),
                    BodyTypes: (data[0]['data']),
                    TransmissionTypesAutomatic: (data[1]['data']),
                    TransmissionTypesGroups: (data[2]['data']),
                    VehicleTypes: (data[3]['data']),
                    ColorTypes: (data[4]['data']),
                    UsedAsTypes: (data[5]['data']),
                    AircoStatusTypes: (data[6]['data']),
                    ConditionTypes: (data[7]['data']),
                    CountryOfOriginTypes: (data[8]['data']),
                    DriveTypes: (data[9]['data']),
                    InteriorTouchTypes: (data[10]['data']),
                    LacTypes: (data[11]['data']),
                    LiningTypes: (data[12]['data']),
                    InteriorColorTypes: (data[13]['data']),
                    MaintenanceGuideTypes: (data[14]['data']),
                    MileageHistoryStatusTypes: (data[15]['data']),
                    TiresTypes: (data[16]['data']),
                    ExtraTiresTypes: (data[17]['data']),
                    Employees: (data[18]['data'])
                };
                localStorage.setItem('OfflineData', JSON.stringify(resData));
            }, error => {
                console.error(JSON.stringify(error));
            });
    }
}
