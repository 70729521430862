export const environment = {
  production: false,
  apiUrl: 'https://api-new-acc.autotaxatiepartners.nl',
  application: 'atp-inspectie',
  password: 'O4X^ZO#ePdUIW1wEM2O>H)UX',
  // First item will be the default, one entry will hide the field.
  level: ['person'],

  byteScaleApiKey: "public_FW25cFJ5zd9JA2fij6Gaf6UD2E1Q",
  byteScaleUploadFolder: "/videos-inspection-acc",
};