import { Injectable } from '@angular/core';

export interface abnormality {
    valuationId: string
    date: string,
    lastUpdated: string
    fields?: [
        abnormalityDetail
    ]
}

export interface abnormalityDetail {
    field: string,
    fieldDescription: string
    value: string,
    comment: string,
}

@Injectable()
export class AbnormalityStorageService {
    savedAbnormalities: [abnormality];
    constructor() {
        this.initStorage();
    }

    private initStorage() {
        if (localStorage.getItem('abnormalities')) {
            // Present.
            this.savedAbnormalities = JSON.parse(localStorage.getItem('abnormalities'));
        } else {
            // Create empty localstorage item.
            localStorage.setItem('abnormalities', JSON.stringify([]))
        }
    }

    public create(valuationId: string, data: abnormalityDetail) {
        this.initStorage();
        // Check if current valuation exists
        if (this.savedAbnormalities != null) {
            if (this.savedAbnormalities.filter(abnormality => abnormality.valuationId == valuationId).length > 0) {
                // Existing abnormality. Check if detail exist (else update)
                const abnormality = this.savedAbnormalities.findIndex(abnormality => abnormality.valuationId == valuationId);
                if (this.savedAbnormalities[abnormality].fields.find(detail => detail.field == data.field)) {
                    // We need to update the detail.
                    this.savedAbnormalities[abnormality].fields[this.savedAbnormalities[abnormality].fields.findIndex(detail => detail.field == data.field)] = {
                        field: data.field,
                        fieldDescription: data.fieldDescription,
                        value: data.value,
                        comment: data.comment
                    }
                    // Save to localstorage.
                    localStorage.setItem('abnormalities', JSON.stringify(this.savedAbnormalities));
                } else {
                    // Does not exist, create detail.
                    this.createAbnormalityDetail(valuationId, data);
                }
            } else {
                // We have abnormalities, but not for this valuation.
                this.createAbnormality(valuationId, data);
                this.createAbnormalityDetail(valuationId, data);
            }
        } else {
            // No abnormalities have been set yet.
            this.createAbnormality(valuationId, data);
            this.createAbnormalityDetail(valuationId, data);
        }
    }

    private createAbnormality(valuationId: string, data: abnormalityDetail) {
        // Create new abnormality-detail field
        const today = new Date().toUTCString();
        this.savedAbnormalities.push({
            valuationId: valuationId,
            date: today,
            lastUpdated: today,
        });
        // Save to localstorage.
        localStorage.setItem('abnormalities', JSON.stringify(this.savedAbnormalities));
    }

    private createAbnormalityDetail(valuationId: string, data: abnormalityDetail) {
        const abnormality = this.savedAbnormalities.findIndex(abnormality => abnormality.valuationId == valuationId);
        if (this.savedAbnormalities[abnormality].fields != null) {
            this.savedAbnormalities[abnormality].fields.push({
                field: data.field,
                fieldDescription: data.fieldDescription,
                value: data.value,
                comment: data.comment
            })
        } else {
            this.savedAbnormalities[abnormality].fields = [{
                field: data.field,
                fieldDescription: data.fieldDescription,
                value: data.value,
                comment: data.comment
            }]
        }
        // Save to localstorage.
        localStorage.setItem('abnormalities', JSON.stringify(this.savedAbnormalities));
    }

    public get(valuationId: string) {
        return (this.savedAbnormalities.findIndex(abnormality => abnormality.valuationId == valuationId));
    }

    public getField(valuationId: string, name: string) {
        const abnormality = (this.savedAbnormalities.find(abnormality => abnormality.valuationId == valuationId));
        return abnormality.fields.find(detail => detail.field == name);
    }

    public getFieldsForValuation(valuationId: string) {
        if(this.savedAbnormalities != null) {
            const result = this.savedAbnormalities.find(abnormality => abnormality.valuationId == valuationId);
            if(result) {
                return result;
            } else {
                return null
            }
        }
    }

    public getAll() {
        return JSON.parse(localStorage.getItem('abnormalities'))
    }
}