import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class SnackBarService {

    constructor(private snackBar: MatSnackBar) {
    }

    /**
    * @param title: Title of the snackbar
    * @param button: Button text of the snackbar (clicking will dismiss)
    */
    public openSnackBar(title: string, button: string) {
        this.snackBar.open(title, button, {
            verticalPosition: 'top',
            duration: 10000,
        });
    }

    public dismiss() {
        this.snackBar.dismiss();
    }
}