import { Injectable } from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}
export interface Saperator {
  name: string;
  type?: string;
}
export interface SubChildren {
  state: string;
  name: string;
  type?: string;
}
export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
  child?: SubChildren[];
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  saperator?: Saperator[];
  children?: ChildrenItems[];
}

const MENUITEMS = [
  {
    state: 'dashboard',
    name: 'Dashboard',
    type: 'link',
    icon: 'dashboard'
  },
  {
    state: 'aanvragen',
    name: 'Aanvragen',
    type: 'link',
    icon: 'call_split'
  },
  {
    state: 'opdrachten',
    name: 'Opdrachten',
    type: 'sub',
    icon: 'manage_search',
    children: [
      // Google Maps
      { type: 'link', state: 'nieuw', name: 'Nieuw' },
      // Google Maps
      { type: 'link', state: 'gepland', name: 'Gepland'},
      { type: 'link', state: 'afgehandeld', name: 'Afgehandeld'},
      { type: 'link', state: 'doorgestuurd', name: 'Doorgestuurd'},
    ]
  },
  {
    state: 'taken',
    name: 'Taken',
    type: 'link',
    icon: 'list'
  },
];

@Injectable()
export class MenuItems {
  getMenuitem(): Menu[] {
    return MENUITEMS;
  }
}
