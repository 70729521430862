import { MediaMatcher } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { MenuItems } from '../../shared/menu-items/menu-items';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { SnackBarService } from '../../services/snackbar.service';
import { ZoekresultatenModalComponent } from '../../pages/modals/zoekresultaten/zoekresultaten.modal';
import { MatDialog } from '@angular/material/dialog';

@Component({
	selector: 'app-full-layout',
	templateUrl: 'full.component.html',
	styleUrls: []
})
export class FullComponent implements OnDestroy {
	mobileQuery: MediaQueryList;

	dir = 'ltr';
	dark = false;
	minisidebar = false;
	boxed = false;
	horizontal = false;

	green = false;
	blue = false;
	danger = false;
	showHide = false;
	url = '';
	sidebarOpened = false;
	status = false;

	searchForm: UntypedFormGroup;

	public showSearch = false;
	public config: PerfectScrollbarConfigInterface = {};

	private _mobileQueryListener: () => void;

	constructor(
		public router: Router,
		changeDetectorRef: ChangeDetectorRef,
		media: MediaMatcher,
		public menuItems: MenuItems,
		private fb: UntypedFormBuilder,
		private apiService: ApiService,
		private snackbarService: SnackBarService,
		private dialog: MatDialog
	) {
		this.mobileQuery = media.matchMedia('(min-width: 1466px)');
		this._mobileQueryListener = () => changeDetectorRef.detectChanges();

		this.searchForm = this.fb.group({
			search: ['']
		});
	}

	ngOnDestroy(): void {
		// tslint:disable-next-line: deprecation
		this.mobileQuery.removeListener(this._mobileQueryListener);
	}


	clickEvent(): void {
		this.status = !this.status;
	}

	onSearch() {
		const searchValue = this.searchForm.get('search')?.value;
		if (searchValue) {
			this.searchForm.get('search')?.setValue('');
			// Strip "-" from searchValue
			const cleanedSearchValue = searchValue.replace(/[-\s]/g, '');

			this.apiService.getInspections(25, 1, JSON.stringify([{ "field": "created", "direction": "DESC" }]), JSON.stringify({ "AND": [["Vehicle.registration", "eq", cleanedSearchValue]] }), JSON.stringify(null)).subscribe((data) => {
				if (data.paginator.records === 0) {
					// Show snackbar
					this.snackbarService.openSnackBar('Er zijn geen inspecties gevonden met dit kenteken.', 'Sluiten');
				} else if (data.paginator.records === 1) {
					this.router.navigate(['/taxatie-detail/' + data.data[0].Valuation.id]);
				} else if (data.paginator.records > 1) {
					// Open modal to make the user choose the right inspection
					this.dialog.open(ZoekresultatenModalComponent, {
						width: '650px',
						data: data.data
					});
				}
			});
		}
	}
}
