import { Routes } from '@angular/router';
import { AuthGuard } from './authentication/helpers/auth.guard';
import { AppBlankComponent } from './layouts/blank/blank.component';
import { FullComponent } from './layouts/full/full.component';
import { MaintenanceComponent } from './shared/maintenance/maintenance.component';

export const AppRoutes: Routes = [
  {
    path: '',
    component: FullComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: 'authentication',
    component: AppBlankComponent,
    children: [
      {
        path: '',
        loadChildren:
          () => import('./authentication/authentication.module').then(m => m.AuthenticationModule)
      },
      {
        path: 'maintenance',
        component: MaintenanceComponent
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'authentication/404'
  },
];
