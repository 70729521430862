import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { apiResponse } from '../models/api-response.model';
import { assessment } from '../models/assessment.model';
import { cancelInspection, inspection, inspectionReportImage, inspectionReportVideo, scheduleInspection, updateInspection } from '../models/inspection';
import { forwardInspection } from '../models/request.model';

@Injectable()
export class ApiService {
    private api_base: string = environment.apiUrl;
    constructor(private http: HttpClient) { }

    /*-------------------------
    == CRM- CALLS ==
    -------------------------*/
    // GET
    getEmployees(limit: number, page: number, orderBy?: string, filter?: any, fields?: any, defaultFilter?: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        Params = Params.append('limit', String(limit));
        Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (defaultFilter) Params = defaultFilter && Params.append('defaultFilter', defaultFilter);
        if (fields) Params = fields && Params.append('fields', fields);
        return this.http.get<apiResponse>(this.api_base + '/crm/v1/employee/', { headers: RequestHeaders, params: Params });
    }

    /*-------------------------
    == CLAIM- CALLS ==
    -------------------------*/
    // GET
    getClaim(id: number, fields: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        if (fields) Params = fields && Params.append('fields', fields);
        return this.http.get<apiResponse>(this.api_base + '/claim/v1/' + id, { headers: RequestHeaders, params: Params });
    }

    /*-------------------------
    == INSPECTION- CALLS ==
    -------------------------*/
    // GET
    getInspections(limit: number, page: number, orderBy?: any, filter?: any, fields?: any, defaultFilter?: any, groupBy?: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        Params = Params.append('limit', String(limit));
        Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (defaultFilter) Params = defaultFilter && Params.append('defaultFilter', defaultFilter);
        if (fields) Params = fields && Params.append('fields', fields);
        if (groupBy) Params = groupBy && Params.append('groupBy', groupBy);

        return this.http.get<apiResponse>(this.api_base + '/inspection/v1/',
            { headers: RequestHeaders, params: Params });
    }

    getInspection(id: number, fields: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        if (fields) Params = fields && Params.append('fields', fields);
        return this.http.get<apiResponse>(this.api_base + '/inspection/v1/' + id, { headers: RequestHeaders, params: Params });
    }

    // POST
    addInspectionReport(data: inspection) {
        const headers = new HttpHeaders();
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        return this.http.post<apiResponse>(this.api_base + '/inspection/v1/report', data, { headers: headers });
    }

    addInspectionReportImage(id: number, data: inspectionReportImage) {
        const headers = new HttpHeaders();
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        return this.http.post<apiResponse>(this.api_base + '/inspection/v1/report/' + id + '/image/', data, { headers: headers });
    }

    addInspectionReportVideo(id: number, data: inspectionReportVideo) {
        const headers = new HttpHeaders();
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        return this.http.post<apiResponse>(this.api_base + '/inspection/v1/report/' + id + '/video/', data, { headers: headers });
    }

    // DELETE
    deleteInspectionReportImage(reportId: number, imageId: number) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        return this.http.delete<apiResponse>(this.api_base + '/inspection/v1/report/' + reportId + '/image/' + imageId, { headers: RequestHeaders, params: Params });
    }

    cancelInspectionRequest(id: any) {
        const RequestHeaders = new HttpHeaders()
            .set('Accept', 'application/json')
        return this.http.delete<apiResponse>(this.api_base + '/request/v1/valuation/' + id + '/inspection-request', { headers: RequestHeaders });
    }

    deleteInspection(id: number) {
        const headers = new HttpHeaders();
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        return this.http.delete<apiResponse>(this.api_base + '/request/v1/valuation/' + id + '/inspection', { headers: headers });
    }

    // PUT
    updateInspection(id: number, data: updateInspection) {
        const headers = new HttpHeaders();
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        return this.http.put<apiResponse>(this.api_base + '/inspection/v1/' + id, data, { headers: headers });
    }

    assessInspection(id: number, data: assessment) {
        const headers = new HttpHeaders();
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        return this.http.put<apiResponse>(this.api_base + '/inspection/v1/' + id + '/assessment', data, { headers: headers });
    }

    scheduleInspection(id: number, data: scheduleInspection) {
        const headers = new HttpHeaders();
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        return this.http.put<apiResponse>(this.api_base + '/inspection/v1/' + id + '/schedule', data, { headers: headers });
    }

    cancelInspection(id: number, data: cancelInspection) {
        const headers = new HttpHeaders();
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        return this.http.put<apiResponse>(this.api_base + '/inspection/v1/' + id + '/cancel', data, { headers: headers });
    }

    updateInspectionReport(inspectionId: number, data: inspection) {
        const headers = new HttpHeaders();
        headers.append('Accept', 'application/json');
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        return this.http.put<apiResponse>(this.api_base + '/inspection/v1/report/' + inspectionId, data, { headers: headers });
    }

    /*-------------------------
    == GENERAL- CALLS ==
    -------------------------*/
    //GET
    dateOperation(operation: string, days: number, date?: string) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        Params = Params.append('operation', String(operation));
        Params = Params.append('days', String(days));
        if (date) Params = Params.append('date', date);

        return this.http.get<apiResponse>(this.api_base + '/api/v1/date',
            { headers: RequestHeaders, params: Params });
    }

    /*-------------------------
    == REQUEST CALLS ==
    -------------------------*/

    // GET
    getValuations(limit: number, page: number, orderBy?: any, filter?: any, fields?: any, defaultFilter?: any, groupBy?: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        Params = Params.append('limit', String(limit));
        Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (defaultFilter) Params = defaultFilter && Params.append('defaultFilter', defaultFilter);
        if (fields) Params = fields && Params.append('fields', fields);
        if (groupBy) Params = groupBy && Params.append('groupBy', groupBy);

        return this.http.get<apiResponse>(this.api_base + '/request/v1/valuation/',
            { headers: RequestHeaders, params: Params });
    }

    getValuation(id: number, fields: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        if (fields) Params = fields && Params.append('fields', fields);
        return this.http.get<apiResponse>(this.api_base + '/request/v1/valuation/' + id, { headers: RequestHeaders, params: Params });
    }

    getBodyTypes(limit?: any, page?: any, orderBy?: string, filter?: any, fields?: any, defaultFilter?: any, groupBy?: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        if (limit) Params = Params.append('limit', String(limit));
        if (page) Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (defaultFilter) Params = defaultFilter && Params.append('defaultFilter', defaultFilter);
        if (fields) Params = fields && Params.append('fields', fields);
        if (groupBy) Params = groupBy && Params.append('groupBy', groupBy);
        return this.http.get<apiResponse>(this.api_base + '/request/v1/body-type/', { headers: RequestHeaders, params: Params });
    }

    getTransmissionTypes(limit?: any, page?: any, orderBy?: string, filter?: any, fields?: any, defaultFilter?: any, groupBy?: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        if (limit) Params = Params.append('limit', String(limit));
        if (page) Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (defaultFilter) Params = defaultFilter && Params.append('defaultFilter', defaultFilter);
        if (fields) Params = fields && Params.append('fields', fields);
        if (groupBy) Params = groupBy && Params.append('groupBy', groupBy);
        if (groupBy) Params = groupBy && Params.append('groupBy', groupBy);
        return this.http.get<apiResponse>(this.api_base + '/request/v1/transmission-type/', { headers: RequestHeaders, params: Params });
    }

    getColorTypes(limit?: any, page?: any, orderBy?: string, filter?: any, fields?: any, defaultFilter?: any, groupBy?: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        if (limit) Params = Params.append('limit', String(limit));
        if (page) Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (defaultFilter) Params = defaultFilter && Params.append('defaultFilter', defaultFilter);
        if (fields) Params = fields && Params.append('fields', fields);
        if (groupBy) Params = groupBy && Params.append('groupBy', groupBy);
        return this.http.get<apiResponse>(this.api_base + '/request/v1/color-type/', { headers: RequestHeaders, params: Params });
    }

    getUsedAsTypes(limit?: any, page?: any, orderBy?: string, filter?: any, fields?: any, defaultFilter?: any, groupBy?: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        if (limit) Params = Params.append('limit', String(limit));
        if (page) Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (defaultFilter) Params = defaultFilter && Params.append('defaultFilter', defaultFilter);
        if (fields) Params = fields && Params.append('fields', fields);
        if (groupBy) Params = groupBy && Params.append('groupBy', groupBy);
        return this.http.get<apiResponse>(this.api_base + '/request/v1/used-as-type/', { headers: RequestHeaders, params: Params });
    }

    getAircoStatusTypes(limit?: any, page?: any, orderBy?: string, filter?: any, fields?: any, defaultFilter?: any, groupBy?: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        if (limit) Params = Params.append('limit', String(limit));
        if (page) Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (defaultFilter) Params = defaultFilter && Params.append('defaultFilter', defaultFilter);
        if (fields) Params = fields && Params.append('fields', fields);
        if (groupBy) Params = groupBy && Params.append('groupBy', groupBy);
        return this.http.get<apiResponse>(this.api_base + '/request/v1/airco-status-type/', { headers: RequestHeaders, params: Params });
    }

    getConditionTypes(limit?: any, page?: any, orderBy?: string, filter?: any, fields?: any, defaultFilter?: any, groupBy?: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        if (limit) Params = Params.append('limit', String(limit));
        if (page) Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (defaultFilter) Params = defaultFilter && Params.append('defaultFilter', defaultFilter);
        if (fields) Params = fields && Params.append('fields', fields);
        if (groupBy) Params = groupBy && Params.append('groupBy', groupBy);
        return this.http.get<apiResponse>(this.api_base + '/request/v1/condition-type/', { headers: RequestHeaders, params: Params });
    }

    getCountryOfOriginTypes(limit?: any, page?: any, orderBy?: string, filter?: any, fields?: any, defaultFilter?: any, groupBy?: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        if (limit) Params = Params.append('limit', String(limit));
        if (page) Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (defaultFilter) Params = defaultFilter && Params.append('defaultFilter', defaultFilter);
        if (fields) Params = fields && Params.append('fields', fields);
        if (groupBy) Params = groupBy && Params.append('groupBy', groupBy);
        return this.http.get<apiResponse>(this.api_base + '/request/v1/country-of-origin-type/', { headers: RequestHeaders, params: Params });
    }

    getDriveTypes(limit?: any, page?: any, orderBy?: string, filter?: any, fields?: any, defaultFilter?: any, groupBy?: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        if (limit) Params = Params.append('limit', String(limit));
        if (page) Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (defaultFilter) Params = defaultFilter && Params.append('defaultFilter', defaultFilter);
        if (fields) Params = fields && Params.append('fields', fields);
        if (groupBy) Params = groupBy && Params.append('groupBy', groupBy);
        return this.http.get<apiResponse>(this.api_base + '/request/v1/drive-type/', { headers: RequestHeaders, params: Params });
    }

    getInteriorTouchTypes(limit?: any, page?: any, orderBy?: string, filter?: any, fields?: any, defaultFilter?: any, groupBy?: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        if (limit) Params = Params.append('limit', String(limit));
        if (page) Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (defaultFilter) Params = defaultFilter && Params.append('defaultFilter', defaultFilter);
        if (fields) Params = fields && Params.append('fields', fields);
        if (groupBy) Params = groupBy && Params.append('groupBy', groupBy);
        return this.http.get<apiResponse>(this.api_base + '/request/v1/interior-touch-type/', { headers: RequestHeaders, params: Params });
    }

    getLacTypes(limit?: any, page?: any, orderBy?: string, filter?: any, fields?: any, defaultFilter?: any, groupBy?: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        if (limit) Params = Params.append('limit', String(limit));
        if (page) Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (defaultFilter) Params = defaultFilter && Params.append('defaultFilter', defaultFilter);
        if (fields) Params = fields && Params.append('fields', fields);
        if (groupBy) Params = groupBy && Params.append('groupBy', groupBy);
        return this.http.get<apiResponse>(this.api_base + '/request/v1/lac-type/', { headers: RequestHeaders, params: Params });
    }

    getLiningTypes(limit?: any, page?: any, orderBy?: string, filter?: any, fields?: any, defaultFilter?: any, groupBy?: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        if (limit) Params = Params.append('limit', String(limit));
        if (page) Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (defaultFilter) Params = defaultFilter && Params.append('defaultFilter', defaultFilter);
        if (fields) Params = fields && Params.append('fields', fields);
        if (groupBy) Params = groupBy && Params.append('groupBy', groupBy);
        return this.http.get<apiResponse>(this.api_base + '/request/v1/lining-type/', { headers: RequestHeaders, params: Params });
    }

    getInteriorColorTypes(limit?: any, page?: any, orderBy?: string, filter?: any, fields?: any, defaultFilter?: any, groupBy?: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        if (limit) Params = Params.append('limit', String(limit));
        if (page) Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (defaultFilter) Params = defaultFilter && Params.append('defaultFilter', defaultFilter);
        if (fields) Params = fields && Params.append('fields', fields);
        if (groupBy) Params = groupBy && Params.append('groupBy', groupBy);
        return this.http.get<apiResponse>(this.api_base + '/request/v1/interior-color-type/', { headers: RequestHeaders, params: Params });
    }

    getMileageHistoryStatusTypes(limit?: any, page?: any, orderBy?: string, filter?: any, fields?: any, defaultFilter?: any, groupBy?: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        if (limit) Params = Params.append('limit', String(limit));
        if (page) Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (defaultFilter) Params = defaultFilter && Params.append('defaultFilter', defaultFilter);
        if (fields) Params = fields && Params.append('fields', fields);
        if (groupBy) Params = groupBy && Params.append('groupBy', groupBy);
        return this.http.get<apiResponse>(this.api_base + '/request/v1/mileage-history-status-type/', { headers: RequestHeaders, params: Params });
    }

    getMaintenanceGuideTypes(limit?: any, page?: any, orderBy?: string, filter?: any, fields?: any, defaultFilter?: any, groupBy?: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        if (limit) Params = Params.append('limit', String(limit));
        if (page) Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (defaultFilter) Params = defaultFilter && Params.append('defaultFilter', defaultFilter);
        if (fields) Params = fields && Params.append('fields', fields);
        if (groupBy) Params = groupBy && Params.append('groupBy', groupBy);
        return this.http.get<apiResponse>(this.api_base + '/request/v1/maintenance-guide-type/', { headers: RequestHeaders, params: Params });
    }

    getTiresTypes(limit?: any, page?: any, orderBy?: string, filter?: any, fields?: any, defaultFilter?: any, groupBy?: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        if (limit) Params = Params.append('limit', String(limit));
        if (page) Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (defaultFilter) Params = defaultFilter && Params.append('defaultFilter', defaultFilter);
        if (fields) Params = fields && Params.append('fields', fields);
        if (groupBy) Params = groupBy && Params.append('groupBy', groupBy);
        return this.http.get<apiResponse>(this.api_base + '/request/v1/tires-type/', { headers: RequestHeaders, params: Params });
    }

    getExtraTiresTypes(limit?: any, page?: any, orderBy?: string, filter?: any, fields?: any, defaultFilter?: any, groupBy?: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        if (limit) Params = Params.append('limit', String(limit));
        if (page) Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (defaultFilter) Params = defaultFilter && Params.append('defaultFilter', defaultFilter);
        if (fields) Params = fields && Params.append('fields', fields);
        if (groupBy) Params = groupBy && Params.append('groupBy', groupBy);
        return this.http.get<apiResponse>(this.api_base + '/request/v1/extra-tires-type/', { headers: RequestHeaders, params: Params });
    }

    getVehicleTypes() {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        return this.http.get<apiResponse>(this.api_base + '/request/v1/vehicle-type/', { headers: RequestHeaders });
    }

    // PUT
    forwardInspection(id: string, data: forwardInspection) {
        const RequestHeaders = new HttpHeaders()
            .set('Accept', 'application/json')
        return this.http.put<apiResponse>(this.api_base + '/request/v1/valuation/' + id + '/inspection', data, { headers: RequestHeaders });
    }

    getTasks(limit: number, page: number, orderBy?: any, filter?: any, fields?: any, defaultFilter?: any, groupBy?: any) {
        const RequestHeaders = new HttpHeaders().set('Accept', 'application/json');
        let Params = new HttpParams()
        Params = Params.append('limit', String(limit));
        Params = Params.append('page', String(page));
        if (orderBy) Params = Params.append('orderBy', orderBy);
        if (filter) Params = filter && Params.append('filter', filter);
        if (defaultFilter) Params = defaultFilter && Params.append('defaultFilter', defaultFilter);
        if (groupBy) Params = groupBy && Params.append('groupBy', groupBy);
        if (fields) Params = fields && Params.append('fields', fields);

        return this.http.get<apiResponse>(this.api_base + '/request/v1/valuation/task',
            { headers: RequestHeaders, params: Params });
    }

    closeValuationTask(id: number) {
        const RequestHeaders = new HttpHeaders()
            .set('Accept', 'application/json')
        return this.http.put<apiResponse>(this.api_base + '/request/v1/valuation/task/' + id + '/close', { headers: RequestHeaders });
    }
}
