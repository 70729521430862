<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div class="main-container" [dir]="dir"
    [ngClass]="{'minisidebar': minisidebar, 'boxed': boxed, 'danger': danger, 'blue': blue, 'green': green, 'horizontal' : horizontal }">

    <!-- ============================================================== -->
    <!-- Topbar - style you can find in header.scss -->
    <!-- ============================================================== -->
    <mat-toolbar color="primary" class="topbar relative">
        <div class="w-100 fix-width-for-horizontal">
            <!-- ============================================================== -->
            <!-- sidebar toggle -->
            <!-- ============================================================== -->
            <button mat-icon-button class="sidebar-toggle" (click)="snav.toggle()">
                <mat-icon>menu</mat-icon>
            </button>

            <!-- ============================================================== -->
            <!-- Logo - style you can find in header.scss -->
            <!-- ============================================================== -->
            <div class="navbar-header">
                <a class="navbar-brand" href="/">
                    <!-- Logo icon -->
                    <b>
                        <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
                        <!-- Dark Logo icon -->
                        <img height="45px" src="/assets/images/logo/logo-light-text.png" alt="homepage">
                    </b>
                    <!--End Logo icon -->
                </a>
            </div>

            <!-- ============================================================== -->
            <!-- Search - style you can find in header.scss -->
            <!-- ============================================================== -->

            <span fxFlex></span>

            <form [formGroup]="searchForm" class="search-form" (ngSubmit)="onSearch()">
                <mat-form-field appearance="outline" class="search-field">
                    <input matInput formControlName="search" placeholder="Zoek een kenteken...">
                    <button mat-icon-button matSuffix type="submit">
                        <mat-icon>search</mat-icon>
                    </button>
                </mat-form-field>
            </form>
        </div>

    </mat-toolbar>
    <!-- ============================================================== -->
    <!-- End Topbar - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <mat-sidenav-container class="example-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 0 : 0"
        [ngClass]="{'minisidebar': minisidebar}">
        <!-- ============================================================== -->
        <!-- Sidebar - style you can find in sidebar.scss -->
        <!-- ============================================================== -->
        <mat-sidenav #snav id="snav" class="pl-xs" [mode]="mobileQuery.matches ? 'side' : 'over'" fixedTopGap="0"
            [opened]="mobileQuery.matches" [disableClose]="mobileQuery.matches" (opened)="sidebarOpened = true"
            (closed)="sidebarOpened = false">

            <perfect-scrollbar *ngIf="!horizontal;" class="scbar" [config]="config">
                <app-vertical-sidebar class="app-sidebar" [showClass]='status' (notify)="snav.toggle()">
                </app-vertical-sidebar>
            </perfect-scrollbar>
        </mat-sidenav>

        <!-- ============================================================== -->
        <!-- Page container - style you can find in pages.scss -->
        <!-- ============================================================== -->
        <mat-sidenav-content class="page-wrapper" [ngClass]="status ? 'minitoggle' : ''">

            <app-breadcrumb></app-breadcrumb>
            <div class="page-content">
                <router-outlet>
                    <app-spinner></app-spinner>
                </router-outlet>

            </div>
        </mat-sidenav-content>
        <!-- ============================================================== -->
        <!-- Page container - style you can find in pages.scss -->
        <!-- ============================================================== -->
    </mat-sidenav-container>
</div>