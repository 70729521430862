<h1 mat-dialog-title>Selecteer een inspectie</h1>
<div mat-dialog-content>
  <p>Er zijn voor dit kenteken meerdere inspecties gevonden.</p>
  <hr>

  <mat-selection-list [multiple]="false" (selectionChange)="navigateValuation($event)">
    <mat-list-option *ngFor="let inspection of data" [value]="inspection.Valuation.id">
      {{inspection.Report.created.date | amDateFormat: 'DD-MM-YYYY - HH:mm'}} - {{inspection.Valuation.InspectionAddress.companyName}}
    </mat-list-option>
  </mat-selection-list>

</div>
<div style="padding-bottom: 20px;" mat-dialog-actions>
  <button mat-raised-button color="warn" (click)="onNoClick()">Sluiten</button>
</div>