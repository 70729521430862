import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' })
export class MaintenanceService {
  private maintenanceMode = new BehaviorSubject<boolean>(false);
  isMaintenance$ = this.maintenanceMode.asObservable();

  // Define your maintenance windows here
  private maintenanceWindows: { start: moment.Moment, end: moment.Moment }[] = [
    // Maintenance windows can be added here in the format shown in commented code
    // { start: moment("2024-01-20 20:00:00"), end: moment("2024-01-21 06:00:00") }
  ];

  constructor() {
    this.checkMaintenanceWindow();
  }

  setMaintenanceMode(status: boolean) {
    this.maintenanceMode.next(status);
  }

  private checkMaintenanceWindow() {
    const now = moment(); // gets the current date and time

    // Check if 'now' is within any of the defined maintenance windows
    const maintenance = this.maintenanceWindows.some(window => {
      // Ensure 'start' and 'end' are defined before comparing
      if (window.start && window.end) {
        return now.isBetween(window.start, window.end);
      }
      return false; // If 'start' or 'end' is not defined, return false
    });

    // If a bypass flag is set in localStorage, don't enable maintenance mode
    if (localStorage.getItem('bypassMaintenance') === 'true') {
      this.setMaintenanceMode(false);
    } else {
      this.setMaintenanceMode(maintenance); // Update the maintenance mode status
    }
  }
}
