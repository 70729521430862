import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LocalStorageService } from '../../../services/localstorage.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-zoekresultaten',
  templateUrl: 'zoekresultaten.modal.html',
  styleUrls: ['zoekresultaten.modal.scss']
})
export class ZoekresultatenModalComponent {
  loading = false;

  constructor(
    public dialogRef: MatDialogRef<ZoekresultatenModalComponent>,
    public localstorageService: LocalStorageService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any) {

  }

  public navigateValuation(valuationId: any) {
    this.router.navigate(['/taxatie-detail/' + valuationId.option._value]);
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.dialogRef.close({result: false, inspector: null});
  }
}
