
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, isDevMode } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FullComponent } from './layouts/full/full.component';
import { AppBlankComponent } from './layouts/blank/blank.component';
import { VerticalAppSidebarComponent } from './layouts/full/vertical-sidebar/vertical-sidebar.component';
import { AppBreadcrumbComponent } from './layouts/full/breadcrumb/breadcrumb.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DemoMaterialModule } from './material-module';
import { SharedModule } from './shared/shared.module';
import { SpinnerComponent } from './shared/spinner.component';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { JwtInterceptor } from './authentication/helpers/jwt.interceptor';
import { ErrorInterceptor } from './authentication/helpers/error.interceptor';
import { AuthenticationService } from './authentication/authentication.service';
import { AuthGuard } from './authentication/helpers/auth.guard';
import { SnackBarService } from './services/snackbar.service';
import { GoogleMapsModule } from '@angular/google-maps'
import { NgxGalleryModule } from '@kolkov/ngx-gallery';
import { MomentModule } from 'ngx-moment';
import { NgxImageCompressService } from 'ngx-image-compress';
import { UploadxModule } from 'ngx-uploadx';
import { UploadWidgetModule } from "@bytescale/upload-widget-angular";

// Primeng imports
import { TableModule } from 'primeng/table';
import { ApiService } from './services/api.service';
import { ButtonModule } from 'primeng/button';
import { MatFileUploadModule } from 'mat-file-upload';
import { TagModule } from 'primeng/tag';
import { LocalStorageService } from './services/localstorage.service';
import { AbnormalityStorageService } from './services/abnormality-storage.service';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { CalendarModule } from 'primeng/calendar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { provideMomentDatetimeAdapter } from '@ng-matero/extensions-moment-adapter';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MaintenanceService } from './authentication/helpers/maintenance.service';
import { MaintenanceInterceptor } from './authentication/helpers/maintenance.interceptor';
import { MaintenanceComponent } from './shared/maintenance/maintenance.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    wheelSpeed: 2,
    wheelPropagation: true
};

@NgModule({
    declarations: [
        AppComponent,
        FullComponent,
        SpinnerComponent,
        AppBlankComponent,
        VerticalAppSidebarComponent,
        AppBreadcrumbComponent,
        MaintenanceComponent
    ],
    bootstrap: [
        AppComponent
    ], imports: [
        HttpClientModule,
        MatFileUploadModule,
        BrowserModule,
        BrowserAnimationsModule,
        DemoMaterialModule,
        FormsModule,
        FlexLayoutModule,
        SharedModule,
        RouterModule.forRoot(AppRoutes),
        GoogleMapsModule,
        NgxGalleryModule,
        TableModule,
        ButtonModule,
        MomentModule,
        UploadxModule,
        CalendarModule,
        NgxSkeletonLoaderModule,
        TagModule,
        ReactiveFormsModule,
        UploadWidgetModule,
        // Date adapter
        MatDatepickerModule,
        MatNativeDateModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: !isDevMode(),
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),
    ], providers: [
        // Date adapters
        provideMomentDatetimeAdapter(),
        provideMomentDateAdapter(),

        ApiService,
        AuthenticationService,
        MaintenanceService,
        AuthGuard,
        SnackBarService,
        NgxImageCompressService,
        AbnormalityStorageService,
        LocalStorageService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MaintenanceInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true
        },
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        },
        { provide: MAT_DATE_LOCALE, useValue: 'nl-NL' },
        provideHttpClient(withInterceptorsFromDi())
    ]
})
export class AppModule { }
